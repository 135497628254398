
import {
  arrowBackOutline,
  arrowForwardOutline,
  mailOutline,
} from "ionicons/icons";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonFooter,
  IonIcon,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  alertController,
  IonLabel,
  IonItem,
} from "@ionic/vue";

import { defineComponent, ref, computed } from "vue";
import { formatDate } from "@/services/filters";
import { useStore } from "@/store";
import { Log } from "@/store/models";
import { showEditLogData } from "@/services/modals";

export default defineComponent({
  name: "Help",
  components: {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonFooter,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonIcon,
    IonLabel,
    IonItem,
  },
  setup() {
    const store = useStore();
    const remoteData = ref();
    const offset = ref(0);

    const logs = computed(() => {
      return remoteData.value ? remoteData.value : store.state.logs;
    });

    return {
      logs,
      offset,
      remoteData,
      formatTimestamp(timestamp: string) {
        return formatDate(timestamp, "DD/MM/YY HH:mm");
      },
      async loadRemote(offsetDelta: number) {
        offset.value += offsetDelta;
        remoteData.value = await store.dispatch("loadRemoteLogs", offset.value);
      },
      showLocalData() {
        remoteData.value = null;
      },
      editDataPopup(log: Log) {
        showEditLogData(log);
      },
      async deleteLog(log: Log) {
        const alert = await alertController.create({
          header: "Confirmer!",
          message:
            "Supprimer le log? (également sur le serveur, si synchronisé)",
          buttons: [
            {
              text: "Annuler",
              role: "cancel",
            },
            {
              text: "Supprimer",
              handler: () => {
                store.dispatch("deleteLog", log);
              },
            },
          ],
        });
        return alert.present();
      },
      exportLogs() {
        store.dispatch("exportLogs");
      },
      mailOutline,
      arrowBackOutline,
      arrowForwardOutline,
    };
  },
});
