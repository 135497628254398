<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
        <ion-title class="ion-text-center">
          <img
            src="assets/icon/logo.png"
            style="height: 40px"
            alt="xtenso|xsample"
          />
        </ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding" scroll-x scroll-y>
      <table class="export-preview">
        <tr>
          <th>TimeStamp</th>
          <th>Resource</th>
          <th>Activity</th>
          <th>Type</th>
          <th>Data</th>
          <th>Sync</th>
          <th>Action</th>
        </tr>
        <tr v-for="l in logs" :key="l.id">
          <td>{{ formatTimestamp(l.timestamp) }}</td>
          <td>{{ l.resourceName }}</td>
          <td>{{ l.activity }}</td>
          <td>{{ l.type }}</td>
          <td>
            <pre>{{ l.data }}</pre>
          </td>
          <td>{{ l.s }}</td>
          <td>
            <ion-button @click="editDataPopup(l)" size="small" color="warning" fill="outline">
              Modifier
            </ion-button>
            <ion-button @click="deleteLog(l)" size="small" color="danger" fill="outline">
              Supprimer
            </ion-button>
          </td>
        </tr>
        <tr>
          <td>...</td>
          <td>...</td>
          <td>...</td>
          <td>...</td>
          <td>...</td>
          <td>...</td>
          <td></td>
        </tr>
      </table>
    </ion-content>
    <ion-footer>
      <ion-button
        size="small"
        fill="outline"
        v-if="!remoteData"
        @click="loadRemote(0)"
      >
        afficher données serveur
      </ion-button>
      <div v-if="remoteData">
        <ion-button size="small" @click="loadRemote(-50)">
          <ion-icon slot="start" :icon="arrowBackOutline"></ion-icon>
          précédent
        </ion-button>
        <span>{{ offset }} - {{ offset + 50 }}</span>
        <ion-button size="small" @click="loadRemote(50)">
          suivant
          <ion-icon slot="end" :icon="arrowForwardOutline"></ion-icon>
        </ion-button>
      </div>
      <ion-button
        size="small"
        fill="outline"
        v-if="remoteData"
        @click="showLocalData()"
      >
        afficher données locales
      </ion-button>
      <ion-item v-if="!remoteData">
        <ion-label>
          <p>Envoyer les données par e-mail:</p>
          <ion-button color="primary" expand="block" @click="exportLogs()">
            <ion-icon slot="start" :icon="mailOutline"></ion-icon>
            {{ $store.state.user.email }}
          </ion-button>
        </ion-label>
      </ion-item>
    </ion-footer>
  </ion-page>
</template>

<script lang="ts">
import {
  arrowBackOutline,
  arrowForwardOutline,
  mailOutline,
} from "ionicons/icons";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonFooter,
  IonIcon,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  alertController,
  IonLabel,
  IonItem,
} from "@ionic/vue";

import { defineComponent, ref, computed } from "vue";
import { formatDate } from "@/services/filters";
import { useStore } from "@/store";
import { Log } from "@/store/models";
import { showEditLogData } from "@/services/modals";

export default defineComponent({
  name: "Help",
  components: {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonFooter,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonIcon,
    IonLabel,
    IonItem,
  },
  setup() {
    const store = useStore();
    const remoteData = ref();
    const offset = ref(0);

    const logs = computed(() => {
      return remoteData.value ? remoteData.value : store.state.logs;
    });

    return {
      logs,
      offset,
      remoteData,
      formatTimestamp(timestamp: string) {
        return formatDate(timestamp, "DD/MM/YY HH:mm");
      },
      async loadRemote(offsetDelta: number) {
        offset.value += offsetDelta;
        remoteData.value = await store.dispatch("loadRemoteLogs", offset.value);
      },
      showLocalData() {
        remoteData.value = null;
      },
      editDataPopup(log: Log) {
        showEditLogData(log);
      },
      async deleteLog(log: Log) {
        const alert = await alertController.create({
          header: "Confirmer!",
          message:
            "Supprimer le log? (également sur le serveur, si synchronisé)",
          buttons: [
            {
              text: "Annuler",
              role: "cancel",
            },
            {
              text: "Supprimer",
              handler: () => {
                store.dispatch("deleteLog", log);
              },
            },
          ],
        });
        return alert.present();
      },
      exportLogs() {
        store.dispatch("exportLogs");
      },
      mailOutline,
      arrowBackOutline,
      arrowForwardOutline,
    };
  },
});
</script>
<style scoped>
table.export-preview {
  width: 100%;
}

table.export-preview td {
	white-space: nowrap;
	border-right: 1px solid grey;
	padding: 2px 4px;
  font-size: 80%;
}

table.export-preview th{
	font-weight: bold;
}

</style>
